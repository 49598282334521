<template>
	<div class="m-con">
		<div class="m-head-bg"></div>
		<div class="u-container u-flex u-col-top u-m-t-60">
			<div class="m-left" v-loading="typeLoading">
				<ul>
					<li v-for="(item,index) in typeList">
						<div class="li-group1 u-line-1" :class="{'li-hover':item.son.length == 0,'li-active':item.id == params.cate_id}" @click="openCollapse(item)">{{item.name}}</div>
						<collapse>
							<div class="li-group-box" v-show="item.show"> 
							<template v-if="item.son.length > 0">
								<template v-for="(item2,index2) in item.son">
									<template v-if="item2.son.length > 0">
										<p  class="li-group2 u-line-1">{{item2.name}}</p>
										<p  class="li-group3 u-line-1" v-for="(item3,index3) in item2.son" :class="{'li-active':item3.id == params.cate_id}" @click="clickType(item3)">{{item3.name}}</p>
									</template>
									<template v-else>
										<p  class="li-group3 u-line-1" :class="{'li-active':item2.id == params.cate_id}" @click="clickType(item2)">{{item2.name}}</p>
									</template>
								</template>
							</template>
							<template v-else>
								<!-- <p  class="li-group3 u-line-1" :class="{'li-active':item2.id == params.cate_id}" v-for="(item2,index2) in item.son">{{item2.name}}</p> -->
							</template>
							</div>
						</collapse>
					</li>
					<!-- <li>
						<div class="li-group1 u-line-1">消防学习</div>
						<div class="li-group-box">
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
						</div>
					</li>
					<li>
						<div class="li-group1 u-line-1">设备学习</div>
						<div class="li-group-box">
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
							<p  class="li-group3 u-line-1">《安全生产法律法规》</p>
						</div>
					</li>
					<li>
						<div class="li-group1 u-line-1">一建学习</div>
					</li>
					<li>
						<div class="li-group1 u-line-1">注安学习</div>
					</li> -->
				</ul>
			</div>
			
			<div class="m-right" v-loading="loading">
				<p class="m-right-title">课程列表</p>
				<div class="m-list">
					<div class="m-item" v-for="(item,index) in list" @click="to_course(item)">
						<div class="m-item-img">
							<div class="m-item-tip">{{item.type == 1 ? '视频' : '文档'}}</div>
							<img :src="item.cover" />
						</div>
						<div class="m-item-title u-line-2">
							<span>{{item.name}}</span>
						</div>
						<div class="u-flex u-row-between u-m-t-20 u-m-l-20 u-m-r-20">
							<div class="m-item-time">
								<img src="../assets/img/common/icon-time4.png" />
								<span class="u-m-l-6">学时安排：{{item.period}}</span>
							</div>
							<div class="m-item-btn">去学习</div>
						</div>
					</div>
				</div>
				<!-- <div class="u-flex u-row-center u-m-b-60">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  @current-change="pageChange"
					  :total="total">
					</el-pagination>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import collapse from "../assets/collapse.js"
	import {to_link} from "../router/api.js"
	import {
		getTopCate,
		getCourseForCateId
	} from "@/api/index.js"
	export default {
		components:{
			collapse
		},
		data() {
			return {
				typeLoading:false,
				params:{
					cate_id:'',
					page:1,
					pageNum:9
				},
				loading:false,
				list:[],
				typeList:[],
				total:0
			}
		},
		computed:{
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		mounted() {
			console.log(this.$route);
			this.getType()
		},
		methods:{
			to_link,
			openCollapse(item){
				if(item.son.length == 0){
					this.clickType(item)
					return false;
				}
				item.show = !item.show
			},
			clickType(item){
				if(item.id == this.params.cate_id) return false;
				this.params.cate_id = item.id
				this.getList()
			},
			//课程详情
			to_course(item){
				if(item.is_allot != 1 && this.isLogin){
					this.$message.error('暂未分配次课程，请联系客服添加')
					return false;
				}
				if(item.type == 1){
					to_link('/video',{id:item.id})
				}else{
					to_link('/files',{id:item.id})
				}
			},
			pageChange(e){
				this.params.page = e
				this.getList()
			},
			async getList(){
				this.loading = true
				let res = await getCourseForCateId(this.params)
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.total = res.count || 0
					this.list = res.data
				}
			},
			async getType(){
				this.typeLoading = true
				let res = await getTopCate({})
				setTimeout(()=>{
					this.typeLoading = false
				},500)
				if(res.code == 200){
					let id = this.$route.params.id
					let key = null
					res.data.forEach((item,index)=>{
						if(item.id == id){
							item['show'] =  true
							key = index
						}else{
							item['show'] =  false
						}
					})
					if(res.data.length > 0){
						let sonId = null
						if(res.data[key].son.length == 0){
							sonId = res.data[key].id
						}else{
							if(res.data[key].son[0].son.length == 0){
								sonId = res.data[key].son[0].id
							}else{
								sonId = res.data[key].son[0].son[0].id
							}
						}
						this.params.cate_id = sonId
						this.typeList = res.data
						this.getList()
					}
				}else{
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-con {
		width: 100%;
		min-height: 600px;
    background: linear-gradient(to bottom,#11a6ff,#2bb0ff,#5bc2ff,#91d6ff,#cbecff,#fdffff) !important;

    padding-bottom: 100px;
	}

	.m-head-bg {
		width: 100%;
		min-width: 1200px;
		height: 430px;
		background: url(../assets/img/common/m-head-bg.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}

	.m-left {
		width: 200px;
		background: #fff;
		padding-top: 8px;

		ul {
			display: flex;
			flex-direction: column;

			li {
				display: flex;
				flex-direction: column;
				width: 200px;
				border-bottom: 1px solid #DDDDDD;
				.li-group1{
					padding-left: 20px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					height: 45px;
					line-height: 45px;
					cursor: pointer;
				}
				.li-active{
					background: #1b87ef!important;
					color: #fff!important;
				}
				.li-hover{
					&:hover{
						background: #1b87ef!important;
						color: #fff!important;
					}
				}
				.li-group-box{
					display: flex;
					flex-direction: column;
					.li-group2{
						padding-left: 20px;
						height: 40px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #1b87ef;
						line-height: 40px;
					}
					.li-group3{
						padding-left: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						height: 40px;
						line-height: 40px;
						cursor: pointer;
						&:hover{
							background: #1b87ef;
							color: #fff;
						}
					}
				}
			}
		}
	}
	
	.m-right{
		width: 960px;
		background: #fff;
		margin-left: 38px;
		.m-right-title{
			display: flex;
			height: 64px;
			line-height: 20px;
			padding-top: 32px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #000000;
			padding-left: 38px;
			border-bottom: 1px solid #EEEEEE;
			box-sizing: border-box;
		}
		.m-list{
			display: flex;
			flex-wrap: wrap;
			padding-top: 48px;
			padding-left: 38px;
			padding-bottom: 60px;
			.m-item{
				width: 280px;
				height: 290px;
				background: #F1F1F1;
				cursor: pointer;
				margin-right: 20px;
				margin-bottom: 20px;
				.m-item-img{
					position: relative;
					width: 280px;
					height: 158px;
					display: flex;
					align-items: center;
					overflow: hidden;
					.m-item-tip{
						position: absolute;
						top: 22px;
						right: 0;
						z-index: 1;
						width: 56px;
						height: 24px;
						font-size: 14px;
						color: #fff;
						opacity: 0.5;
						background: #000;
						text-align: center;
						line-height: 22px;
					}
					img{
						width: 100%;
					}
				}
				.m-item-title{
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #525252;
					line-height: 16px;
					height: 36px;
					margin: 24px 20px 0 20px;
				}
				.m-item-time{
					display: flex;
					align-items: center;
					color: #7C7C7C;
					font-size: 14px;
					line-height: 16px;
				}
				.m-item-btn{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 80px;
					height: 28px;
					background: #D6102A;
					border-radius: 14px;
					font-size: 14px;
					color: #fff;
				}
			}
		}
	}
</style>
